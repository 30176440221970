import { render, staticRenderFns } from "./NotPremiumModal.vue?vue&type=template&id=f43eb54c&scoped=true&"
import script from "./NotPremiumModal.vue?vue&type=script&lang=js&"
export * from "./NotPremiumModal.vue?vue&type=script&lang=js&"
import style0 from "./NotPremiumModal.vue?vue&type=style&index=0&id=f43eb54c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f43eb54c",
  null
  
)

export default component.exports