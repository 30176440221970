import { render, staticRenderFns } from "./AlbumList.vue?vue&type=template&id=77416626&scoped=true&"
import script from "./AlbumList.vue?vue&type=script&lang=js&"
export * from "./AlbumList.vue?vue&type=script&lang=js&"
import style0 from "./AlbumList.vue?vue&type=style&index=0&id=77416626&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77416626",
  null
  
)

export default component.exports