import { render, staticRenderFns } from "./PerformerView.vue?vue&type=template&id=5e291f03&scoped=true&"
import script from "./PerformerView.vue?vue&type=script&lang=js&"
export * from "./PerformerView.vue?vue&type=script&lang=js&"
import style0 from "./PerformerView.vue?vue&type=style&index=0&id=5e291f03&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e291f03",
  null
  
)

export default component.exports