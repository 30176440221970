import { render, staticRenderFns } from "./ComposerList.vue?vue&type=template&id=6f0d083c&scoped=true&"
import script from "./ComposerList.vue?vue&type=script&lang=js&"
export * from "./ComposerList.vue?vue&type=script&lang=js&"
import style0 from "./ComposerList.vue?vue&type=style&index=0&id=6f0d083c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f0d083c",
  null
  
)

export default component.exports